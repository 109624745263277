import Layout from '../components/layout'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { useMutation } from 'react-query'
import { FunctionComponent } from 'react'
import { SignupClient } from '../utils/signupClient'
import * as EmailValidator from 'email-validator'
import React from 'react'
const client = new SignupClient()
const Login: FunctionComponent = () => {
  const [mutation, mutationStatus] = useMutation(async (email: string) => {
    return client.getLogin(email)
  })

  const [emailInput, setEmailInput] = React.useState<string>()
  const [validatedEmail, setValidatedEmail] = React.useState<
    string | undefined
  >(undefined)
  const handleUpdateInput = (input: string) => {
    mutationStatus.reset()
    setEmailInput(input)
    setValidatedEmail(EmailValidator.validate(input) ? input : undefined)
  }
  const canSubmit = validatedEmail !== undefined && !mutationStatus.isLoading
  console.log(mutationStatus)

  return (
    <Layout
      backgroundColor="#FAFAFA"
      pageTitle={`Login To LightTag `}
      pageDescription={'Login To LightTag'}
    >
      <Container>
        <Grid
          style={{ height: '100vh' }}
          container
          alignItems={'stretch'}
          spacing={4}
          justify={'center'}
        >
          <Grid
            alignItems={'center'}
            item
            container
            direction={'column'}
            xs={12}
            md={8}
          >
            <Grid item>
              <Typography align="center" variant={'h1'}>
                LightTag Login
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                disabled={mutationStatus.isLoading}
                value={emailInput}
                onChange={e => handleUpdateInput(e.target.value)}
                label={'Email'}
                variant={'outlined'}
                fullWidth={true}
                placeholder={'my.lighttag@email.com'}
                helperText={
                  "We'll confirm your email and send you to your account"
                }
              />
              <Grid item>
                <Button
                  disabled={!canSubmit}
                  fullWidth={true}
                  variant={'outlined'}
                  onClick={() => {
                    if (validatedEmail) {
                      mutation(validatedEmail)
                    }
                  }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Fade in={mutationStatus.isError}>
                  <Typography color={'error'}>
                    {mutationStatus.error ? mutationStatus.error.reason : null}
                  </Typography>
                </Fade>
              </Grid>
              <Grid item>
                <Fade in={mutationStatus.isLoading}>
                  <LinearProgress />
                </Fade>
              </Grid>
              <Grid item>
                <Fade in={mutationStatus.isSuccess}>
                  <Typography color={'primary'}>
                    We've sent you an email with a link to your account
                  </Typography>
                </Fade>
              </Grid>
            </Grid>
          </Grid>
          <Grid></Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
export default Login
